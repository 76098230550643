import { sortByOrder, sortByProperty } from '@/helpers/sort';

export const gridDefaultColDef = {
  resizable: true,
  sortable: true,
};

export function gridColumns(ProfileLink) {
  return [
    {
      headerName: 'Committee',
      field: 'committee',
      flex: 1,
      sort: 'asc',
      autoHeight: true,
      sortIndex: 0,
      sortable: false,
      valueGetter: (params) => params.data.positions[0].parent.name,
      comparator: (valueA, valueB) => sortByOrder(valueA, valueB, ['Search Committee', 'Selection Committee']),
    },
    {
      headerName: 'Role',
      field: 'role',
      flex: 1,
      sort: 'asc',
      sortIndex: 1,
      sortable: false,
      valueGetter: (params) => params.data.positions[0].name,
      comparator: (valueA, valueB) => sortByOrder(valueA, valueB, ['Chair', 'Member']),
    },
    {
      headerName: 'Full Name',
      flex: 1,
      sort: 'asc',
      sortIndex: 2,
      sortable: false,
      valueGetter: (params) => params.data,
      cellRenderer: ProfileLink,
      comparator: (valueA, valueB) => sortByProperty(valueA, valueB, 'surname'),
    },
  ];
}

export function gridRowStyle(params) {
  const allRowData = [];
  params.api.forEachNodeAfterFilterAndSort((node) => {
    allRowData.push(node);
  });

  // Get First Search Committee
  const scNode = allRowData.find((node) => node.data.positions[0].parent.name === 'Selection Committee');

  if (scNode) {
    if (scNode.rowIndex === params.node.rowIndex) {
      return {
        borderTop: '2px solid black',
      };
    }
  }

  return undefined;
}
